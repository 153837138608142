import * as React from 'react'
import styled from 'styled-components'

// Components
import Calendar from 'components/home/calendar'
import { FixedContainer } from 'components/styled/containers'
import { SCREEN } from 'styles/screens'
import { getTranslation } from '../../data/dictionary'

// Styled
const Container = styled.div`
  // padding-bottom: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 100px;
  // margin-top: 100px;
  margin-bottom: 60px;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 16px;
  }

  &:before {
    position: absolute;
    content: 'Lifecycle';
    font-size: 22vw;
    user-select: none;
    font-weight: 700;
    left: 0;
    transform: translateX(0);
    top: -150px;
    opacity: 5%;
    @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
      // font-size: 180px;
      top: -40px;
    }
    @media only screen and (max-width: ${SCREEN.MOBILE}) {
      // font-size: 180px;
      top: -60px;
    }
    
  }
`

const StyledFixedContainer = styled(FixedContainer)`
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 100%;
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    flex-direction: column;
    align-items: flex-start;
  }
`
const Title = styled.h3`
  font-weight: normal;
  font-family: 'Lexend';  
  width: 100%;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    font-size: 24px;
    margin: 10px 0;
  }
`

const HighlighterText = styled.h2`
  background-color: var(--primary);
  color: var(--secondary);
  padding: 0 50px 8px 50px;
  margin-left: 8%;
  margin-top: 20px;
  margin-bottom: 40px;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    font-size: 2.2rem;
    padding: 16px;
    margin: 0;
    align-self: start;
  }
`

const CalendarSection = () => (
  <Container>
    <StyledFixedContainer>
      <Title>
        <b>Classes</b> starting in
      </Title>
      <HighlighterText>
        {getTranslation("classes_starting_in","January 2022",false)}
      </HighlighterText>
      <Calendar />
    </StyledFixedContainer>
  </Container>
)

export default CalendarSection
